import React, { useContext } from "react";
import { url } from "../../config/website";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import BreadCrumbs from "../components/BreadCrumps";
import { transformSlugToArrBreadcrumbs } from "../helpers/transformSlugToArr";
import LinksBox from "../components/LinksBox";
import SchemesColors from "../components/PageColorPalette/SchemesColors";
import { WebPageJsonLD } from "../components/SEO/WebPageJsonLD";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import { ContextLocale } from "../context/ContextLocale";
import { content_wrapper } from "../styles/modules/content.module.css";
import { allLanguagesLinks } from "../helpers/consts";
import HrefLang from "../components/SEO/HrefLang";
import { MorePalettesWithColors } from "../components/more-palettes-with-color";

const PagePaletteColor = ({ data }) => {
  const { setLang } = useContext(ContextLocale);
  const { mdx } = data;
  setLang(mdx.frontmatter.locale ? mdx.frontmatter.locale : "es");

  const embeddedImagesObject = mdx => {
    if (mdx.frontmatter.embeddedImages) {
      return mdx.frontmatter.embeddedImages[0].name.includes("-")
        ? mdx.frontmatter.embeddedImages.reduce((images, image) => {
            images[image.name.replace(/-/g, "_")] = images[image.name] || {
              ...image.childImageSharp,
            };

            return images;
          }, {})
        : mdx.frontmatter.embeddedImages.reduce((images, image) => {
            images[image.name] = images[image.name.replace(/-/g, "_")] || {
              ...image.childImageSharp,
            };

            return images;
          }, {});
    } else return;
  };

  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale ? mdx.frontmatter.locale : "es"
  );

  return (
    <Layout locale={mdx.frontmatter.locale}>
      {mdx.frontmatter.color !== "gris" ? (
        <SchemesColors
          color={mdx.frontmatter.color}
          hexColor={mdx.frontmatter.hex}
          height="100vh"
          locale={mdx.frontmatter.locale}
        />
      ) : null}

      <BreadCrumbs breadCrumbsList={breadCrumbsList} />

      <article className={content_wrapper}>
        <h1>{mdx.frontmatter.title} </h1>
        <MorePalettesWithColors
          hex={mdx.frontmatter.hex}
          locale={mdx.frontmatter.locale}
          colorName={mdx.frontmatter.color}
          withInitialPalette={true}
        />
        <MDXRenderer embeddedImages={embeddedImagesObject(mdx)}>
          {mdx.body}
        </MDXRenderer>
        <MorePalettesWithColors
          hex={mdx.frontmatter.hex}
          locale={mdx.frontmatter.locale}
          colorName={mdx.frontmatter.color}
          withInitialPalette={false}
        />
      </article>

      <LinksBox slug={mdx.frontmatter.slug} locale={mdx.frontmatter.locale} />
    </Layout>
  );
};

export const Head = ({ data }) => {
  const { mdx } = data;

  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale ? mdx.frontmatter.locale : "es"
  );

  const [links] = allLanguagesLinks.colors.filter(elem =>
    elem[mdx.frontmatter.locale]
      ? elem[mdx.frontmatter.locale].includes(mdx.frontmatter.slug)
      : null
  );
  return (
    <>
      <BasicHeadTags
        title={mdx.frontmatter.metaTitle}
        description={mdx.frontmatter.metaDescription}
        type="article"
        image={`${url}${mdx.frontmatter.featuredImage.publicURL}`}
        slug={mdx.frontmatter.slug}
        lang={mdx.frontmatter.locale}
        canonicalUrl={`${url}${mdx.frontmatter.slug}`}
      />
      <HrefLang links={links} />
      <WebPageJsonLD
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.metaDescription}
        imagePublicURL={mdx.frontmatter.featuredImage.publicURL}
        imageWidth={
          mdx.frontmatter.featuredImage.childImageSharp.original.width
        }
        imageHeight={
          mdx.frontmatter.featuredImage.childImageSharp.original.height
        }
        slug={mdx.frontmatter.slug}
        date={mdx.frontmatter.date}
        modified={mdx.frontmatter.modified}
        breadcrumbs={breadCrumbsList}
      />
    </>
  );
};

export default PagePaletteColor;

export const data = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        slug
        metaTitle
        metaDescription
        hex
        color
        date
        locale
        modified
        featuredImage {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
          }
        }
        embeddedImages {
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
